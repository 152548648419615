import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import {Booking} from './pages/Booking';
import { ServicesPage } from './pages/ServicesPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Contact" element={<Contact />} /> {/* Contact page */}
        <Route path="/Booking" element={<Booking />} /> {/* Booking page */}
        <Route path="/Services" element={<ServicesPage />} /> {/* Services page */}
      </Routes>
    </Router>
  );
}

export default App;
