import React, { useState } from 'react';
import './book.css'; // Import CSS for styling

const servicesData = {
  'Desert Safari': [
    {
      name: 'Morning Desert Safari',
      price: 'AED 550',
      description: 'Sunset Photography, Camel Riding, BBQ Buffet Dinner, Belly Dance Show, Tanoura Dance Show, Quad Bike/Buggy Riding (Additional)',
      image: '/images/standard.jpg',
    },
    {
      name: 'Evening Desert Safari',
      price: 'AED 550',
      description: 'Sandboarding, Camel Riding, Food, Dance',
      image: '/images/Imageslider4.jpg',
    },
    {
      name: 'Morning Without Sunrise',
      price: 'AED 150',
      description: 'Sandboarding, Camel Riding, Food, Dance',
      image: '/images/desertsafariservice.jpg',
    },
  ],
  'City Tours': [
    {
      name: 'Dubai City Tour',
      price: 'Price : N/A',
      description: 'Half-day/Full-day sightseeing tour covering major landmarks in both old and modern Dubai. Visit Burj Khalifa, Burj Al Arab, Dubai Creek, Jumeirah Beach, Jumeirah Mosque, and more.',
      image: '/images/dubai.jpg',
    },
    {
      name: 'Abu Dhabi City Tour',
      price: 'Price : N/A',
      description: 'Sightseeing tour to Abu Dhabi, the richest city on Earth. Visit Sheikh Zayed Grand Mosque, Emirates Palace, Abu Dhabi Downtown, Heritage Village, Dates Market, Ferrari World, and more.',
      image: '/images/abudhabi.jpg',
    },
    { name: 'Sharjah & Ajman Tour', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/sharjah.jpg' },
    { name: 'Al Ain Tour', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/alain.jpg' },
    { name: 'Hatta Tour', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/hatta.jpg' },
    { name: 'Khor Fakkan Tour', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/khorfakkan.jpg' },
  ],
  'Theme Parks': [
    { name: 'Miracle Garden & Global Village', price: 'Price : Based On Ticket', description: 'Contact Us For Further Details', image: '/images/MiracleGarden.jpeg' },
    { name: 'Warner Bros, Ferrari World', price: 'Price : Based On Ticket', description: 'Contact Us For Further Details', image: '/images/warnerbros.webp' },
    { name: 'IMG World, Dubai Park', price: 'Price : Based On Ticket', description: 'Contact Us For Further Details', image: '/images/imgworld.webp' },
    { name: 'Wild Wadi, Dubai Park', price: 'Price : Based On Ticket', description: 'Contact Us For Further Details', image: '/images/wildwadi.avif' },
    { name: 'Dubai Park, Legoland, Motion Gate', price: 'Price : Based On Ticket', description: 'Contact Us For Further Details', image: '/images/dubaiparks.jpg' },
    { name: 'Ski Dubai Ticket', price: 'Price : Based On Ticket', description: 'Contact Us For Further Details', image: '/images/skidubai.jpg' },
  ],
  'Cruise Tours': [
    {
      name: 'Dhow Cruise & 5 Star Yacht Cruise',
      price: 'Price : N/A',
      description: `
        Enjoy a memorable 2-hour Dhow Cruise Dinner evening. Includes International Buffet Dinner, Tanoura Show, and photography with beautiful views of Dubai Marina & Dubai Creek.
        Experience a luxurious 3-hour cruise in the Arabian Sea Marina. Includes International Buffet Dinner, International Music, Tanoura Show, and photography with stunning views.
      `,
      image: '/images/dhowcruise.jpg', // Use the appropriate image path
    },
    { name: 'Oman Musandam Tour', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/omanmusandam.jpg' },
  ],
  'Dubai Activities': [
    { name: 'Hot Air Balloon Ride', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/hotairballoon.jpg' },
    { name: 'Skydiving', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/SkyDiving.jpg' },
    { name: 'Helicopter Tour', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/helicopter.jpg' },
    { name: 'Jet Ski Rent', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/jetski.jpg' },
    { name: 'Kayaking in Mangroves', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/kayaking.jpg' },
    { name: 'Deep Sea Fishing', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/fishing.webp' },
  ],
  'Hotel': [
    { name: 'Hotel Booking', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/Hotel.jpg' },
  ],
};

export const Book = () => {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    numberOfPeople: 1,
    dietaryRestrictions: '',
    transportation: 'No', // Default to "No"
  });

  const [completedSections, setCompletedSections] = useState({
    details: false,
    package: false,
    preferences: false,
  });

  const [activeSection, setActiveSection] = useState('details');

  const handlePackageSelect = (category, index) => {
    setSelectedPackage({ category, index });
    setCompletedSections((prev) => ({ ...prev, package: true }));
    setActiveSection('preferences'); // Automatically move to the next section
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate required fields
    if (!formData.name || !formData.email || !formData.phone || !formData.date || !formData.numberOfPeople) {
      alert('Please fill all required fields.');
      return;
    }

    if (selectedPackage === null) {
      alert('Please select a package.');
      return;
    }

    // Get the selected package
    const selectedService = servicesData[selectedPackage.category][selectedPackage.index];

    // Prepare the form data
    const formDataToSubmit = new FormData();
    formDataToSubmit.append('entry.1575409873', formData.name); // Name
    formDataToSubmit.append('entry.255266675', formData.email); // Email
    formDataToSubmit.append('entry.1200954384', formData.phone); // Phone
    formDataToSubmit.append('entry.1284530051', formData.numberOfPeople); // Number of People
    formDataToSubmit.append('entry.1830689970', formData.dietaryRestrictions); // Dietary Restrictions
    formDataToSubmit.append('entry.1623971871', formData.transportation); // Transportation (Yes/No)
    formDataToSubmit.append('entry.1449259213', selectedService.name); // Package Selection

    // Extract year, month, and day from the date
    const date = new Date(formData.date);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-indexed in JavaScript
    const day = date.getDate();
    formDataToSubmit.append('entry.287041084_year', year); // Date (Year)
    formDataToSubmit.append('entry.287041084_month', month); // Date (Month)
    formDataToSubmit.append('entry.287041084_day', day); // Date (Day)

    // Submit the form to Google Forms
    const googleFormBaseUrl = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSeczTiIBJmKyeeLNncdF-vVdDAf8xzRAWLjGJSIwB_7-ExA4A/formResponse';
    fetch(googleFormBaseUrl, {
      method: 'POST',
      body: new URLSearchParams(formDataToSubmit), // Convert form data to URL-encoded format
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      mode: 'no-cors', // Disable CORS
    })
      .then(() => {
        alert('Booking successful!'); // Updated success message
        // Optionally reset the form
        setFormData({
          name: '',
          email: '',
          phone: '',
          date: '',
          numberOfPeople: 1,
          dietaryRestrictions: '',
          transportation: 'No',
        });
        setSelectedPackage(null);
        setCompletedSections({ details: false, package: false, preferences: false });
        setActiveSection('details');
      })
      .catch(() => {
        // Since the request is sent, but the response is blocked by CORS, we assume it's successful
        alert('Booking successful!');
        // Optionally reset the form
        setFormData({
          name: '',
          email: '',
          phone: '',
          date: '',
          numberOfPeople: 1,
          dietaryRestrictions: '',
          transportation: 'No',
        });
        setSelectedPackage(null);
        setCompletedSections({ details: false, package: false, preferences: false });
        setActiveSection('details');
      });
  };

  const handleNextSection = () => {
    if (activeSection === 'details') {
      if (!formData.name || !formData.email || !formData.phone || !formData.date || !formData.numberOfPeople) {
        alert('Please fill all required fields.');
        return;
      }
      setCompletedSections((prev) => ({ ...prev, details: true }));
      setActiveSection('package');
    } else if (activeSection === 'preferences') {
      handleSubmit(new Event('submit')); // Trigger form submission
    }
  };

  return (
    <div className="book-container">
      <h1 className="title">Book Your Experience</h1>

      {/* Customer Details Section */}
      <div className="section">
        <div
          className="section-header"
          onClick={() => setActiveSection('details')}
        >
          <h2>
            Your Details
            {completedSections.details && <span className="tick">✔</span>}
          </h2>
        </div>
        <div
          className={`section-content ${activeSection === 'details' ? 'open' : ''}`}
        >
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Phone:</label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Date:</label>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Number of People:</label>
              <input
                type="number"
                name="numberOfPeople"
                value={formData.numberOfPeople}
                onChange={handleInputChange}
                min="1"
                required
              />
            </div>
            <button
              type="button"
              className="submit-button"
              onClick={handleNextSection}
            >
              Next
            </button>
          </form>
        </div>
      </div>

      {/* Package Selection Section */}
      <div className="section">
        <div
          className="section-header"
          onClick={() => setActiveSection('package')}
        >
          <h2>
            Select Your Package
            {completedSections.package && <span className="tick">✔</span>}
          </h2>
        </div>
        <div
          className={`section-content ${activeSection === 'package' ? 'open' : ''}`}
        >
          {Object.entries(servicesData).map(([category, services]) => (
            <div key={category} className="category-section">
              <h3>{category}</h3>
              <div className="packages-grid">
                {services.map((service, index) => (
                  <div
                    key={index}
                    className={`package-card ${
                      selectedPackage?.category === category && selectedPackage?.index === index ? 'selected' : ''
                    }`}
                    onClick={() => handlePackageSelect(category, index)}
                  >
                    <img src={service.image} alt={service.name} className="package-image" />
                    <h4>{service.name}</h4>
                    <p className="price">{service.price}</p>
                    <p className="description">{service.description}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Additional Preferences Section */}
      <div className="section">
        <div
          className="section-header"
          onClick={() => setActiveSection('preferences')}
        >
          <h2>
            Additional Preferences
            {completedSections.preferences && <span className="tick">✔</span>}
          </h2>
        </div>
        <div
          className={`section-content ${activeSection === 'preferences' ? 'open' : ''}`}
        >
          <div className="additional-preferences">
            <p>Do you have any dietary restrictions?</p>
            <input
              type="text"
              name="dietaryRestrictions"
              value={formData.dietaryRestrictions}
              onChange={handleInputChange}
              placeholder="e.g., Vegetarian, Gluten-free"
            />
            <p>Do you need transportation?</p>
            <select
              name="transportation"
              value={formData.transportation}
              onChange={handleInputChange}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <button
              type="button"
              className="submit-button"
              onClick={handleNextSection}
            >
              Confirm Booking
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};