import React from 'react';
import './Footer.css';
import { FaWhatsapp, FaInstagram, FaPhone, FaEnvelope } from 'react-icons/fa'; // Import Font Awesome icons

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p className="footer-text">
          Click on the icons below to get in touch with us!
        </p>
        <div className="icon-row">
          <a href="mailto:noortourismsafari@gmail.com" aria-label="Email">
            <FaEnvelope />
          </a>
          <a
            href="https://wa.me/971501951041"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="WhatsApp"
          >
            <FaWhatsapp /> {/* WhatsApp icon */}
          </a>
          <a
            href="https://instagram.com/nartexperiences_dubai"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <FaInstagram /> {/* Instagram icon */}
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;