import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useLocation and useNavigate
import './DifferentServices.css'; // Import CSS for styling

export const DifferentServices = () => {
  // State to track the selected service category
  const [selectedCategory, setSelectedCategory] = useState('Desert Safari');

  // Hook for navigation and location
  const navigate = useNavigate();
  const location = useLocation();

  // Data for each service category
  const servicesData = {
    'Desert Safari': [
      {
        name: 'Morning Desert Safari',
        price: 'AED 550',
        description: `
          Start your day with an exhilarating Morning Desert Safari! Enjoy the thrill of dune bashing, sandboarding, and a short camel ride. 
          Capture stunning sunrise photographs and indulge in a light breakfast. Perfect for early risers looking for adventure and breathtaking desert views.
        `,
        image: '/images/standard.jpg',
      },
      {
        name: 'Evening Desert Safari',
        price: 'AED 550',
        description: `
          Experience the magic of the desert at sunset with our Evening Desert Safari. Enjoy dune bashing, sandboarding, and camel riding. 
          Relish a delicious BBQ buffet dinner under the stars, accompanied by live entertainment including belly dancing and Tanoura shows. 
          A perfect blend of adventure and cultural immersion.
        `,
        image: '/images/Imageslider4.jpg',
      },
      {
        name: 'Morning Without Sunrise',
        price: 'AED 150',
        description: `
          For those who prefer a later start, our Morning Without Sunrise Safari offers all the excitement of a desert adventure without the early wake-up call. 
          Enjoy sandboarding, camel riding, and a light breakfast. A great option for families and groups looking for a relaxed yet thrilling experience.
        `,
        image: '/images/desertsafariservice.jpg',
      },
    ],
    'City Tours': [
      {
        name: 'Dubai City Tour',
        price: 'Price : N/A',
        description: 'Half-day/Full-day sightseeing tour covering major landmarks in both old and modern Dubai. Visit Burj Khalifa, Burj Al Arab, Dubai Creek, Jumeirah Beach, Jumeirah Mosque, and more.',
        image: '/images/dubai.jpg',
      },
      {
        name: 'Abu Dhabi City Tour',
        price: 'Price : N/A',
        description: 'Sightseeing tour to Abu Dhabi, the richest city on Earth. Visit Sheikh Zayed Grand Mosque, Emirates Palace, Abu Dhabi Downtown, Heritage Village, Dates Market, Ferrari World, and more.',
        image: '/images/abudhabi.jpg',
      },
      { name: 'Sharjah & Ajman Tour', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/sharjah.jpg' },
      { name: 'Al Ain Tour', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/alain.jpg' },
      { name: 'Hatta Tour', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/hatta.jpg' },
      { name: 'Khor Fakkan Tour', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/khorfakkan.jpg' },
    ],
    'Theme Parks': [
      { name: 'Miracle Garden & Global Village', price: 'Price : Based On Ticket', description: 'Contact Us For Further Details', image: '/images/MiracleGarden.jpeg' },
      { name: 'Warner Bros, Ferrari World', price: 'Price : Based On Ticket', description: 'Contact Us For Further Details', image: '/images/warnerbros.webp' },
      { name: 'IMG World, Dubai Park', price: 'Price : Based On Ticket', description: 'Contact Us For Further Details', image: '/images/imgworld.webp' },
      { name: 'Wild Wadi, Dubai Park', price: 'Price : Based On Ticket', description: 'Contact Us For Further Details', image: '/images/wildwadi.jpg' },
      { name: 'Dubai Park, Legoland, Motion Gate', price: 'Price : Based On Ticket', description: 'Contact Us For Further Details', image: '/images/dubaiparks.jpg' },
      { name: 'Ski Dubai Ticket', price: 'Price : Based On Ticket', description: 'Contact Us For Further Details', image: '/images/skidubai.jpg' },
    ],
    'Cruise Tours': [
      {
        name: 'Dhow Cruise & 5 Star Yacht Cruise',
        price: 'Price : N/A',
        description: `
          Enjoy a memorable 2-hour Dhow Cruise Dinner evening. Includes International Buffet Dinner, Tanoura Show, and photography with beautiful views of Dubai Marina & Dubai Creek.
          Experience a luxurious 3-hour cruise in the Arabian Sea Marina. Includes International Buffet Dinner, International Music, Tanoura Show, and photography with stunning views.
        `,
        image: '/images/dhowcruise.jpg',
      },
      { name: 'Oman Musandam Tour', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/omanmusandam.jpg' },
    ],
    'Dubai Activities': [
      { name: 'Hot Air Balloon Ride', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/hotairballoon.jpg' },
      { name: 'Skydiving', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/SkyDiving.jpg' },
      { name: 'Helicopter Tour', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/helicopter.jpg' },
      { name: 'Jet Ski Rent', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/jetski.jpg' },
      { name: 'Kayaking in Mangroves', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/kayaking.jpg' },
      { name: 'Deep Sea Fishing', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/fishing.webp' },
    ],
    'Hotel': [
      { name: 'Hotel Booking', price: 'Price : N/A', description: 'Contact Us For Further Details', image: '/images/Hotel.jpg' },
    ],
  };

  // Function to handle booking navigation
  const handleBookNow = () => {
    navigate('/Booking'); // Navigate to the Booking page
  };

  // Use effect to set the selected category from location state
  useEffect(() => {
    if (location.state?.selectedCategory) {
      setSelectedCategory(location.state.selectedCategory);
    }
  }, [location.state]);

  return (
    <div className="different-services">
      {/* Disclaimer Box */}
      <div className="disclaimer-box">
        <p>Note : If the price is listed as "N/A", please contact us for pricing details.</p>
      </div>

      {/* Service Categories */}
      <div className="service-categories">
        {Object.keys(servicesData).map((category) => (
          <button
            key={category}
            className={`category-button ${selectedCategory === category ? 'active' : ''}`}
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Service Packages */}
      <div className="service-packages">
        <div className="packages-grid">
          {servicesData[selectedCategory].map((packageItem, index) => (
            <div key={index} className="package-card">
              <img src={packageItem.image} alt={packageItem.name} className="package-image" />
              <h3>{packageItem.name}</h3>
              <p className="price">{packageItem.price}</p>
              <p className="description">{packageItem.description}</p>
              <button className="book-now-button" onClick={handleBookNow}>Book Now</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};