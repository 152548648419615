import React from 'react'
import Navbar from '../components/Navbar';
import {Background} from '../components/Booking/Background';
import {Book} from '../components/Booking/Book';


export const Booking = () => {
  return (
    <>
    <Navbar />
    <Background />
    <Book />
  </>
  )
}
