import React from 'react';
import { FaWhatsapp, FaInstagram, FaPhone, FaEnvelope } from 'react-icons/fa'; // Import Font Awesome icons
import './hero.css'; // Import CSS for styling

export const Hero = () => {
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission

    // Get form data
    const formData = new FormData(e.target);

    // Validate required fields
    const name = formData.get('entry.1636291327'); // Replace with your Google Form field name for "Your Name"
    const email = formData.get('entry.1454235957'); // Replace with your Google Form field name for "Email Address"
    const message = formData.get('entry.910661631'); // Replace with your Google Form field name for "Message"

    if (!name || !email || !message) {
      alert('Please fill out all required fields.');
      return;
    }

    // Submit the form to Google Forms
    const formAction = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLScy12e1adhaEvmR5jBpNc_YygFYgWybyXpaDFpH9DICImIaYA/formResponse'; // Replace with your Google Form submission URL
    fetch(formAction, {
      method: 'POST',
      body: new URLSearchParams(formData), // Convert form data to URL-encoded format
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'manual', // Prevent automatic redirect handling
    })
      .then(() => {
        alert('Feedback Sent!'); // Show a common success message
        e.target.reset(); // Clear the form
      })
      .catch(() => {
        alert('Feedback Sent!'); // Show the same message even if there's an error
      });
  };

  return (
    <div className="hero-container">
      {/* Black Background Section */}
      <div className="black-background">
        <div className="contact-text">CONTACT US</div>
        <div className="icon-row">
          <a href="mailto:noortourismsafari@gmail.com" aria-label="Email">
            <FaEnvelope />
          </a>
          <a
            href="https://wa.me/971501951041"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="WhatsApp"
          >
            <FaWhatsapp /> {/* WhatsApp icon */}
          </a>
          <a
            href="https://instagram.com/nartexperiences_dubai"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <FaInstagram /> {/* Instagram icon */}
          </a>
        </div>
      </div>

      {/* White Box Section */}
      <div className="white-box">
        <h2 className="title">Get In Touch</h2>
        <form onSubmit={handleSubmit}>
          <div className="content">
            {/* Left Side: Input Boxes */}
            <div className="left-side">
              <input
                type="text"
                name="entry.1636291327" // Replace with your Google Form field name for "Your Name"
                placeholder="Your Name"
                className="input-box"
                required
              />
              <input
                type="email"
                name="entry.1454235957" // Replace with your Google Form field name for "Email Address"
                placeholder="Email Address"
                className="input-box"
                required
              />
              <textarea
                name="entry.910661631" // Replace with your Google Form field name for "Message"
                placeholder="Message"
                className="input-box message-box"
                required
              />
              <button type="submit" className="send-button">
                Send
              </button>
            </div>

            {/* Right Side: Paragraph */}
            <div className="right-side">
  <p>
    For direct bookings, queries, or assistance, get in touch with us via WhatsApp at:{" "}
    <a href="https://wa.me/971501951041" target="_blank" rel="noopener noreferrer">
      +971 50 195 1041
    </a>{" "}
    or email us at:{" "}
    <a href="mailto:noortourismsafari@gmail.com">noortourismsafari@gmail</a>.
    Follow us on Instagram for more updates:{" "}
    <a href="https://instagram.com/nartexperiences_dubai" target="_blank" rel="noopener noreferrer">
      @nartexperiences_dubai
    </a>.
  </p>
</div>
          </div>
        </form>
      </div>
    </div>
  );
};