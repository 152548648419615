import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './Services.css';

const services = [
  {
    title: 'Desert Safari',
    image: '/images/desert1.jpg', // Replace with your image path
  },
  {
    title: 'City Tours',
    image: '/images/Dubai3.jpg', // Replace with your image path
  },
  {
    title: 'Theme Parks',
    image: '/images/YaswaterWorld.jpg', // Replace with your image path
  },
  {
    title: 'Cruise Tours',
    image: '/images/Cruise2.jpg', // Replace with your image path
  },
  {
    title: 'Dubai Activities',
    image: '/images/Activites.jpg', // Replace with your image path
  },
  {
    title: 'Hotel',
    image: '/images/Hotel.jpg', // Replace with your image path
  },
];

function Services() {
  const navigate = useNavigate(); // Hook for navigation

  // Function to handle card click
  const handleServiceClick = (category) => {
    navigate('/Services', { state: { selectedCategory: category } }); // Navigate with state
  };

  return (
    <div className="services" id="services">
      <h2>Our Services</h2>
      <div className="services-grid">
        {services.map((service, index) => (
          <div
            key={index}
            className="service-card-link"
            onClick={() => handleServiceClick(service.title)} // Handle click
          >
            <div className="service-card">
              <img src={service.image} alt={service.title} className="service-image" />
              <div className="service-overlay">
                <h3 className="service-title">{service.title}</h3>
              </div>
              <div className="service-hover-content">
                <p>Check Details</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;