import React from 'react'
import Navbar from '../components/Navbar';
import { Hero } from '../components/Contact/Hero';

function Contact() {
  return (
    <>
      <Navbar />
      <Hero />
    </>
  );
}

export default Contact;