import React, { useState } from 'react';
import './Reviews.css';

function Reviews() {
  const [selectedReview, setSelectedReview] = useState(null); // Track the selected review
  const [isModalOpen, setIsModalOpen] = useState(false); // Track modal visibility

  const reviews = [
    {
      name: 'Victor Kelvin',
      review: "Stunning sights, fun theme parks, and excellent service made Noor al Ritan's Dubai city tour unforgettable!",
      description: `
        I had the luxury of using Noor al Ritan’s Dubai city tour service and i must say it was truly amazing! The sights were so beautiful, especially the Burj Khalifa and Dubai Marina, ive seen nothing liek that before. SO peacefula dn serene. The theme parks were super fun, with so many rides and shows for all ages. Had a great time with family and my kids cant wait to visit dubai and go there again.  Everything was well-organized, and the service was excellent. I’m so happy I chose this tour—it made my trip to Dubai unforgettable in the true mamner!
      `,
      photo: '/images/profilephoto2.jpeg', // Replace with actual photo URL
    },
    {
      name: 'Fajer Al Mazroui',
      review: 'A unique desert adventure—peaceful camel rides, thrilling sandboarding, and a delicious BBQ under the stars made me see the desert in a new way',
      description: `
       The desert safari was unforgettable journey, something very unique even for beoble like me who lived in UAE all life. Camel ride was so peaceful, and sandboarding was so fun, like something new for me. The BBQ dinner under the stars was so delicious, perfect end for amazing day. I recommend this for everyone, even locals like me, to see our desert in new way. yalla masalama
      `,
      photo: '/images/profilephoto1.jpg', // Replace with actual photo URL
    },
    {
      name: 'Rahul Ansari',
      review: 'Thrilling dune bashing, serene camel rides, and a stunning sunset made this an unforgettable adventure!',
      description: `
        I had an amazing unforgettable adventure blending adrenaline, culture, and natural beauty. From thrilling dune bashing to serene camel rides, every moment was exhilarating. We were lucky enough to get to view the sunset which was absolutely mind blowing and gave a sense of peace. I would definitely recommend this service to my friends and family so they too could have a once in a lifetime experience.
      `,
      photo: '/images/profilephoto3.jpeg', // Replace with actual photo URL
    },
  ];

  // Open modal with selected review
  const openModal = (review) => {
    setSelectedReview(review);
    setIsModalOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedReview(null);
  };

  return (
    <section className="reviews">
      <h2>What Our Customers Say</h2>
      <div className="review-list">
        {reviews.map((review, index) => (
          <div
            key={index}
            className="review-card"
            onClick={() => openModal(review)} // Open modal on click
          >
            <img
              src={review.photo}
              alt={review.name}
              className="profile-photo"
            />
            <p>"{review.review}"</p> {/* Add quotation marks */}
          </div>
        ))}
      </div>

      {/* Modal */}
      {isModalOpen && selectedReview && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-modal" onClick={closeModal}>
              &times;
            </button>
            <div className="modal-layout">
              <div className="modal-left">
                <img
                  src={selectedReview.photo}
                  alt={selectedReview.name}
                  className="modal-profile-photo"
                />
                <h3>{selectedReview.name}</h3>
              </div>
              <div className="modal-right">
                <p className="modal-description">{selectedReview.description}</p> {/* Display description */}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default Reviews;