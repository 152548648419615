import React from 'react';
import './Background.css'; // Import CSS for styling

export const Background = () => {
  return (
    <div className="booking-hero-container">
      {/* Black Background Section */}
      <div className="booking-black-background">
        <div className="booking-contact-text">BOOKING PAGE</div>
      </div>
    </div>
  );
};