import React from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import ImageSlider from '../components/ImageSlider';
import Reviews from '../components/Reviews';
import Services from '../components/Services';
import Gallery from '../components/Gallery';
import Footer from '../components/Footer';
const images = [
  '/images/Imageslider1.jpg',
  '/images/Imageslider2.jpg',
  '/images/Imageslider3.jpg',
  '/images/Imageslider4.jpg',
];

function Home() {
  return (
    <>
      <Navbar />
      <Hero />
      <Services />
      <ImageSlider images={images} autoPlayInterval={5000} />
      <Reviews />
      <Gallery />
      <Footer />
    </>
  );
}

export default Home;
