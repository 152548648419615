import React from 'react'
import Navbar from '../components/Navbar';
import { Banner } from '../components/Service/Banner';
import { DifferentServices } from '../components/Service/DifferentServices';
import Footer from '../components/Footer';

export const ServicesPage = () => {
  return (
    <>
    <Navbar />
    <Banner />
    <DifferentServices />
    <Footer />
  </>
  )
}
