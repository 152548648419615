import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './Hero.css';

function Hero() {
  const [scrolling, setScrolling] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  // Scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Handle button click
  const handleBookNow = () => {
    navigate('/Booking'); // Navigate to the Booking page
  };

  return (
    <div className={`hero ${scrolling ? 'scrolled' : ''}`}>
      <div className="hero-content">
        <h1>We foster experiences that you'll cherish forever</h1>
        <h2>Book Your Experience Now</h2>
        <button onClick={handleBookNow} className="hero-button">
          Book Now
        </button>
      </div>
    </div>
  );
}

export default Hero;