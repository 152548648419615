import React from 'react';
import './Gallery.css';

function Gallery() {
  const images = [
    { src: '/images/BungeeJumping.jpg', text: 'Edge Walk' },
    { src: '/images/desert2.jpg', text: 'Exciting Dune Bashing' },
    { src: '/images/desert3.jpg', text: 'Night Camps' },
    { src: '/images/adventure.jpg', text: 'Dirt Bike' },
    { src: '/images/Cruise.jpg', text: 'Cruise' },
    { src: '/images/Imageslider1.jpg', text: 'Rally' },
    { src: '/images/Dubai1.jpg', text: 'City Tour' },
    { src: '/images/FerrariWorld.jpg', text: 'Theme Parks' },
    { src: '/images/Dubai2.jpg', text: 'Outer City' },
    { src: '/images/Kayak.webp', text: 'Kayaking' },
    { src: '/images/MiracleGarden.jpeg', text: 'Out Door' },
    { src: '/images/overnight.jpg', text: 'Night Time' },
  ];

  return (
    <section className="gallery" id="gallery">
      <h2>Gallery</h2>
      <div className="gallery-images">
        {images.map((image, index) => (
          <div key={index} className="gallery-item" style={{ backgroundImage: `url(${image.src})` }}>
            <p className="gallery-text">{image.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Gallery;
