import React from 'react';
import './Banner.css'; // Import CSS for styling

export const Banner = () => {
  return (
    <div className="services-hero-container">
      {/* Black Background Section */}
      <div className="services-black-background">
        <div className="services-hero-text">SERVICES</div>
      </div>
    </div>
  );
};