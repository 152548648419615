import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [isVisible, setIsVisible] = useState(false); // State to control visibility
  const [hasScrolled, setHasScrolled] = useState(false); // State to track if the user has scrolled
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHasScrolled(true); // User has scrolled
        setIsVisible(true); // Show navbar when scrolling down
      } else if (hasScrolled) {
        // If the user has scrolled before, keep the navbar visible even at the top
        setIsVisible(true);
      } else {
        setIsVisible(false); // Hide navbar only if the user hasn't scrolled yet
      }
    };

    // Check if the current page has enough content to scroll
    const checkScrollable = () => {
      const body = document.body;
      const html = document.documentElement;
      const isScrollable = body.scrollHeight > html.clientHeight || html.scrollHeight > html.clientHeight;

      // If the page is not scrollable, make the navbar visible
      if (!isScrollable) {
        setIsVisible(true);
      } else {
        // If the page is scrollable, add the scroll event listener
        window.addEventListener('scroll', handleScroll);
      }
    };

    checkScrollable(); // Check on initial render and route change
    window.addEventListener('resize', checkScrollable); // Recheck on window resize

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', checkScrollable);
    };
  }, [location.pathname, hasScrolled]); // Re-run effect when the route changes or user scrolls

  const handleSectionClick = (id) => {
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    } else {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const handleContactClick = (e) => {
    e.preventDefault();
    navigate('/contact');
  };

  const handleServiceClick = (e) => {
    e.preventDefault();
    navigate('/Services');
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <nav className={`navbar ${isVisible ? 'visible' : 'hidden'}`}>
      <div className="navbar-logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
        <img src="/images/Logo.png" alt="Logo" />
        <span>NOOR AL RITAN</span>
        <span>TOURISM LLC</span>
      </div>
      <ul className="navbar-links">
        <li>
          <a href="/Services" onClick={handleServiceClick}>
            Services
          </a>
        </li>
        <li>
          <a
            href="#gallery"
            onClick={(e) => {
              e.preventDefault();
              handleSectionClick('gallery');
            }}
          >
            Gallery
          </a>
        </li>
        <li>
          <a href="/contact" onClick={handleContactClick}>
            Contact
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;